import { API_BASE_URL } from ".";
const ADMIN = `${API_BASE_URL}/admin`;

const ENDPOINTS = {
  auth: {
    login: `${ADMIN}/auth/login`,
    verify: `${ADMIN}/auth/verify`,
  },
  profile: `${ADMIN}/profiles`,
  user: `${ADMIN}/users`,
  assistant: `${ADMIN}/assistants`,
};

export default ENDPOINTS;
