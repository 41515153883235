import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

const user = {
  name: "Whitney Francis",
  email: "whitney.francis@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};

const MenuItem = ({ children, className }) => {
  return <Menu.Item>{({ active }) => <div className={`block px-3 py-3 ${className}`}>{children}</div>}</Menu.Item>;
};

export default function UserMenu() {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2">
        <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-20 mt-2 w-56 origin-top-right bg-white border">
          <div className="flex flex-col items-center bg-gray-100 p-4 border-b">
            <span className="text-sm font-semibold text-gray-700">Farbod Hadi</span>
            <span className="text-sm text-gray-500 truncate">thisisfarbod@gmail.cdom</span>
          </div>
          <div>
            <MenuItem className="border-b hover:bg-gray-100 cursor-pointer">
              <Link to="profile" className="text-sm text-gray-700 block">
                Profile
              </Link>
            </MenuItem>
            <MenuItem className="hover:bg-red-50 cursor-pointer">
              <Link to="/auth/logout" className="text-sm text-red-500">
                Sign Out
              </Link>
            </MenuItem>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
