import RouteContainer from "@components/core/RouteContainer";
import MasterLayout from "@components/layouts/MasterLayout";
import { lazy } from "react";
import { Navigate } from "react-router-dom";

const UsersPage = lazy(() => import("@modules/users/UsersPage"));
const AssistantsPage = lazy(() => import("@modules/assistants/AssistantsPage"));
const Dashboard = lazy(() => import("@modules/dashboard/Dashboard"));

const paths = [
  { path: "/auth/*", element: <Navigate to="/dashboard" /> },
  { path: "/dashboard/*", element: <Dashboard /> },
  { path: "/users/*", element: <UsersPage /> },
  { path: "/assistants/*", element: <AssistantsPage /> },
];

export default function PrivateRoutes() {
  return <RouteContainer paths={paths} layout={<MasterLayout />} />;
}
