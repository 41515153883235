import axios from "axios";
import { Formik } from "formik";
import { useContext, useState } from "react";
import { useMutation } from "react-query";
import { AppButton, AppInput, RowInput } from "../../components/Form";
import ENDPOINTS from "../../config/endpoints";
import { AuthContext } from "../../context/AuthContext";

export default function LoginPage() {
  const [error, setError] = useState(null);
  const { auth } = useContext(AuthContext);

  // Login Mutation
  const LDM = useMutation((values) => axios.post(ENDPOINTS.auth.login, values), {
    onSuccess: (response) => {
      if (response.token) auth.login(response.token);
    },
    onError: (error) => {
      setError(error);
    },
  });

  return (
    <div className="flex h-screen justify-center items-center p-8">
      <div className="bg-white p-4 shadow w-96">
        <Formik initialValues={{ email: "", password: "" }} onSubmit={LDM.mutate}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div className="flex flex-col space-y-2">
                <RowInput required type="text" name="email" component={AppInput} placeholder="Email" />
                <RowInput required name="password" type="password" placeholder="Password" />
                <AppButton mode="primary" title="T.SIGN_IN" loading={LDM.isLoading} />
                {error &&
                  error?.messages?.map((message, _index) => (
                    <div key={_index} className="bg-red-200 mt-3  h-[50px] flex items-center justify-center">
                      {message}
                    </div>
                  ))}
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
