import { toast } from "react-toastify";
const props = {
  position: "bottom-right",
  autoClose: 1000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
};
const success = (text) =>
  // Success
  toast.success(text, {
    ...props,
  });

// Warning
const warning = (text) => toast.warning(text, { ...props });

// Danger
const danger = (text) => toast.danger(text, { ...props });

export default { success, warning, danger };
