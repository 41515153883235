import DesktopHeader from "./header/DesktopHeader";
import MobileHeader from "./header/MobileHeader";

export default function Header() {
  return (
    <header className="relative flex h-12 flex-shrink-0 items-center bg-gray-700 border-b">
      <DesktopHeader />
      <MobileHeader />
    </header>
  );
}
