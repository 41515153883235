import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import UserMenu from "../UserMenu";

export default function DesktopHeader() {
  const intl = useIntl();
  const MENUS = useMemo(
    () => [
      { title: "Users", path: "/users" },
      { title: "Assistants", path: "/assistants" },
    ],
    []
  );
  return (
    <div className="hidden md:flex md:min-w-0 md:flex-1 md:items-center md:justify-between ml-20">
      <nav aria-label="Global" className="min-w-0 flex-1 flex items-center space-x-10">
        {MENUS.map(({ title, path }, _index) => (
          <Link to={path} key={_index} className="text-sm  text-gray-100 hover:text-white">
            {intl.formatMessage({ id: title })}
          </Link>
        ))}
      </nav>
      <div className="ml-10 flex flex-shrink-0 items-center space-x-10 pr-4">
        <UserMenu />
      </div>
    </div>
  );
}
